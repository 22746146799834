import "stylesheets/init/components/menu.scss";
import Scrollbar from "smooth-scrollbar";
import $ from "jquery";

document.addEventListener("DOMContentLoaded", function () {
  const scrollContainer = document.querySelector("#scrollbar-container");
  let scrollbar;
  if (scrollContainer) scrollbar = Scrollbar.init(scrollContainer);
  scrollbar?.addListener((status) => {
    if (status.offset.y > 5)
      document
        .querySelector(".admin-sidebar .admin-menu .menu-header")
        .classList.add("nav-shadow");
    else
      document
        .querySelector(".admin-sidebar .admin-menu .menu-header")
        .classList.remove("nav-shadow");
  });

  // Close sidebar on mobile
  document
    .querySelector(".admin-sidebar .admin-sidebar-close")
    ?.addEventListener("click", () => {
      document
        .querySelector(".admin-sidebar")
        .classList.remove("admin-sidebar-mobile-open");
      document.querySelector("body").style.overflow = null;
    });

  // Open sidebar on mobile
  document
    .querySelector(".admin-mobile-header .toggle-menu")
    ?.addEventListener("click", () => {
      document
        .querySelector(".admin-sidebar")
        .classList.add("admin-sidebar-mobile-open");
      document.querySelector("body").style.overflow = "hidden";
    });

  // Toggle collapse
  document
    .querySelector(
      ".admin-sidebar .menu-header .toggle-collapse-sidebar#sidebar-toggle-collapse",
    )
    ?.addEventListener("click", () => {
      localStorage.setItem("jumpsellerCollapsedSidebar", "collapsed");
      document.documentElement.classList.add("collapsed-sidebar");
      $(".admin-sidebar li.menu-item .collapse").collapse("hide");
    });
  document
    .querySelector(
      ".admin-sidebar .menu-header .toggle-collapse-sidebar#sidebar-toggle-open",
    )
    ?.addEventListener("click", () => {
      localStorage.removeItem("jumpsellerCollapsedSidebar");
      document.documentElement.classList.remove("collapsed-sidebar");
      $(".admin-sidebar li.menu-item.active .collapse").collapse("show");
    });

  // Prevent opening the desktop submenu-items when on tablet devices and with the menu collapsed
  $(".admin-sidebar li.menu-item .collapse").on("show.bs.collapse", (e) => {
    if (
      document.documentElement.classList.contains("collapsed-sidebar") &&
      (window.innerWidth || document.documentElement.clientWidth) >= 768
    )
      e.preventDefault();
  });

  // scroll if the entire submenu is not visible
  $(".admin-sidebar li.menu-item .collapse").on("shown.bs.collapse", (e) => {
    scrollbar?.scrollIntoView(e.target.parentElement, {
      alignToTop: false,
      onlyScrollIfNeeded: false,
    });
  });

  // Show dropdowns on the side when hovering elements, when sidebar is collapsed
  // list of timeouts for upcoming disappering dropdowns
  let timeouts = [];
  document
    .querySelectorAll(
      ".admin-sidebar .menu-body li.menu-item[id^=menu-section-]",
    )
    .forEach((section) => {
      const sectionName = section.id.replace("menu-section-", "");
      // when hovering the menu item
      section.addEventListener("mouseenter", () => {
        if (!document.documentElement.classList.contains("collapsed-sidebar"))
          return;

        // remove all timeouts -> all dropdowns on the side will be hidden
        timeouts.forEach((timeout) => clearTimeout(timeout));
        timeouts = [];
        // hide all dropdowns on the side that are open
        document
          .querySelectorAll(
            ".collapsed-sidebar .admin-sidebar div.hover-dropdown.hovered",
          )
          .forEach((elem) => {
            elem.style.removeProperty("transform");
            elem.classList.remove("hovered");
          });

        // open the correct dropdown on the side
        const dropdown = document.querySelector(
          `.collapsed-sidebar .admin-sidebar div.hover-dropdown[id=hoverDropdown${sectionName}]`,
        );
        if (!dropdown) return;

        dropdown.classList.add("hovered");
        // get offset to translate dropdown to the correct position
        const offset = getOffset(section, dropdown);
        // if 0 its because its already on correct position
        if (offset.top != 0 && offset.bottom != 0) {
          const dropdownOffset = dropdown.getBoundingClientRect();
          // check if all dropdown fits on screen, if not pushes it up until the bottom of the dropdown lines up with bottom of item menu
          const isInside = isElementInViewport({
            top: dropdownOffset.top + offset.top,
            bottom: dropdownOffset.bottom + offset.top,
          });
          dropdown.style.transform = `translateY(${
            isInside ? offset.top : offset.bottom
          }px)`;
        }
      });
      // when stops hovering the menu item
      section.addEventListener("mouseleave", () => {
        if (!document.documentElement.classList.contains("collapsed-sidebar"))
          return;

        const dropdown = document.querySelector(
          `.collapsed-sidebar .admin-sidebar div.hover-dropdown[id=hoverDropdown${sectionName}]`,
        );
        if (!dropdown) return;
        // add timeout to hide dropdown. Timeout so it can be clicked when switching from the menu item to the dropdown
        timeouts.push(
          setTimeout(() => {
            dropdown.classList.remove("hovered");
            dropdown.style.removeProperty("transform");
          }, 400),
        );
      });
    });

  // handle disappearing timeouts when hovering and leaving the dropdowns to the side
  document
    .querySelectorAll(".admin-sidebar div.hover-dropdown")
    .forEach((elem) => {
      elem.addEventListener("mouseenter", () => {
        // stop disappearing timeout when hovering the dropdown
        const lastTimeout = timeouts.pop();
        if (lastTimeout) clearTimeout(lastTimeout);
      });

      elem.addEventListener("mouseleave", () => {
        // add timeout to hide dropdown
        timeouts.push(
          setTimeout(() => {
            elem.classList.remove("hovered");
            elem.style.removeProperty("transform");
          }, 400),
        );
      });
    });
});

function getOffset(elemBase, elem) {
  const posElemBase = elemBase.getBoundingClientRect();
  const posElem = elem.getBoundingClientRect();

  return {
    top: posElemBase.top - posElem.top,
    bottom: posElemBase.bottom - posElem.bottom,
    left: posElemBase.left - posElem.left,
    right: posElemBase.right - posElem.right,
  };
}

function isElementInViewport(elem) {
  return (
    elem.top >= 0 &&
    elem.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  );
}
