const helpers = {
  basePath: function () {
    let paths = location.pathname.split("/");
    let base_path =
      paths[2].length == 2 || (paths[2].length == 5 && paths[2][2] == "-")
        ? paths.slice(0, 3)
        : paths.slice(0, 2);
    return base_path.join("/");
  },
};

export default helpers;
