import * as Sentry from "@sentry/react";
import { getCSRFToken } from "./utils.js";

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  dsn: "https://a262337dd5764d189e71b44a7baa46d4@o161052.ingest.sentry.io/6759965",
  tunnel: `/admin/sentry_react_tunnel?authenticity_token=${getCSRFToken()}`,
  sampleRate: 0.1, // Only send 1 out of 10, so we avoid exhausting our quota
  // Session Replay
  /* Uncomment when needed. There's a performance impact of 50-150ms on
   * my local machine loading the Navigation index. Lazy-loading as
   * suggested by the Sentry docs does not help.
   */
  // integrations: [Sentry.replayIntegration()],
  // replaysSessionSampleRate: 0.0,
  // replaysOnErrorSampleRate: 1.0,
});

export default Sentry;
