import i18n from "i18next";
import Backend from "i18next-xhr-backend";
// TODO: needs a working custom detector for the admin URL - https://github.com/i18next/i18next-browser-languageDetector
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import axios from "axios";
import helpers from "./helpers";

i18n
  .use(Backend)
  //.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",

    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    ns: ["translations"],

    // remove duplicate call to en-US
    load: "languageOnly",

    useSuspense: true,

    backend: {
      // loadPath: '/admin/{{lng}}/locales'

      // previous URL is not being interpolated with current path. build it manually - https://github.com/i18next/i18next-xhr-backend
      loadPath: function (lngs, namespaces) {
        return helpers.basePath() + "/locales";
      },

      ajax: function (url, options, callback, data) {
        axios.get(url).then((response) => {
          callback(JSON.stringify(response.data), { status: "200" });
        });
      },
    },
  });

export default i18n;
