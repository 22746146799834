export function getCSRFToken() {
  const csrfToken = document.querySelector("[name='csrf-token']");

  if (csrfToken) {
    return csrfToken.content;
  } else {
    return null;
  }
}

export function ensureArray(value) {
  if (Array.isArray(value)) {
    return value;
  }

  return [value];
}
