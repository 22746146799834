// General CSS
import "stylesheets/init/layout.bs.scss";
// Images

function importAll(r) {
  return r.keys().map(r);
}

importAll(require.context("images/icons/solid/", false, /\.(svg)$/));
importAll(require.context("images/icons/regular/", false, /\.(svg)$/));
import "images/icons/solid.svg";
import "images/icons/regular.svg";
import "images/icons/light.svg";
import "images/js-logo_white_text.svg";
import "images/js-logo_black_text.svg";
// Commons
import "stylesheets/init/components/onboarding_notification.css";
import "./components/menu";

// Typekit
(function (d) {
  var config = {
      kitId: "cnr0brb",
      scriptTimeout: 3000,
      async: true,
    },
    h = d.documentElement,
    t = setTimeout(function () {
      h.className = h.className.replace(/\bwf-loading\b/g, "") + " wf-inactive";
    }, config.scriptTimeout),
    tk = d.createElement("script"),
    f = false,
    s = d.getElementsByTagName("script")[0],
    a;
  h.className += " wf-loading";
  tk.src = "https://use.typekit.net/" + config.kitId + ".js";
  tk.async = true;
  tk.onload = tk.onreadystatechange = function () {
    a = this.readyState;
    if (f || (a && a != "complete" && a != "loaded")) return;
    f = true;
    clearTimeout(t);
    try {
      Typekit.load(config);
    } catch (e) {}
  };
  s.parentNode.insertBefore(tk, s);
})(document);

// Bootstrap
$(function () {
  $('[data-toggle="tooltip"]').tooltip();
});
